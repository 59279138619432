<template>
  <div class="form-group row">
    <div class="col-sm-10 checkbox-holder border-checkbox-section">
      <div class="border-checkbox-group border-checkbox-group-primary">
        <input type="checkbox"
               :id="field.id"
               class="border-checkbox"
               v-model="value"
               :disabled="field.disabled">
        <label class="border-checkbox-label" :for="field.id">
          {{ $t(field.label) }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserCheck",
  props: ['field'],
  data() {
    return {
      value: false
    }
  },
  watch: {
    value(value) {
      const field = Object.assign(this.field, {value});
      this.$emit('push', field);
    },
    '$v.$invalid':  {
      handler(val) {
        this.field = Object.assign(this.field, {invalid: val})
        this.$emit('invalid')
      }
    }
  },
  mount() {
    this.value = this.field.value;
  },
  methods: {
    setValueComponent() {
      this.value = this.field.value;
      if (this.field.toPayload) {
        this.$emit('push', this.field)
      }
    }
  }
}
</script>

<style>
 .border-checkbox-section .border-checkbox-group-primary input:disabled ~ .border-checkbox-label::before {
  border: 2px solid #7b7b7b;
}
 .checkbox-holder {
   margin-left: 110px;
 }
</style>