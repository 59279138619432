<template>
  <div class="form-group" :class="field.classWidth">
    <div class="row">
      <div class="user-form-label">
        <span>{{ $t(field.label) }}</span>
      </div>
      <div class="user-form-field">
        <multiselect v-model="value"
                     :options="field.items.map(item => item[field.usedValue])"
                     :multiple="true"
                     :close-on-select="false"
                     :clear-on-select="false"
                     :preserve-search="true"
                     :disabled="field.disabled"
                     selectLabel=""
                     deselectLabel=""
                     selectedLabel=""
                     :placeholder="$t('user.select_option')"
                     :taggable="false">
          <span slot="noResult">{{ $t('user.no_result') }}</span>
          <template slot="tag" slot-scope="{option, remove}">
            <span class="multiselect__tag">
              <span>{{field.translation ? $t(`${field.model}.${labels[option]}`) : labels[option]}}</span>
              <i tabindex="1" class="multiselect__tag-icon" @click="remove(option)"></i>
            </span>
          </template>
          <template slot="option" slot-scope="{option}">
            <span>{{field.translation ? $t(`${field.model}.${labels[option]}`) : labels[option]}}</span>
          </template>
        </multiselect>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
  name: "UserCheckers",
  props: ['field'],
  components: {
    Multiselect
  },
  data() {
    return {
      value: []
    }
  },
  computed: {
    labels() {
      const labels = {};
      for(const item of this.field.items) {
        labels[item[this.field.usedValue]] = item.name;
      }
      return labels;
    }
  },
  methods: {
    setValueComponent() {
      this.value = this.field.value;
    },
  },
  watch: {
    field: {
      deep: true,
      handler(val) {
        this.value = val.value;
      }
    },
    value(value) {
      const field = Object.assign(this.field, {value});
      this.$emit('push', field);
    }
  },

}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.multiselect__option--selected:after {
  display: inline-block;
  content: "";
  height: 40px;
  width: 15px;
  background-color: #f3f3f3;
  background-image: url("/images/check-solid.svg");
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: center;
}
.multiselect__option--selected.multiselect__option--highlight {
  background-color: #d7d7d7;
  color: #35495e;
}
.multiselect__option--selected.multiselect__option--highlight:after {
  background-color: #d7d7d7;
  background-image: url("/images/check-solid.svg");
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: center;
}
.multiselect__option--highlight {
  background-color: #f3f3f3;
  color: #35495e;
}
.multiselect__tags {
  border-radius: 2px;
  border: 1px solid rgb(204, 204, 204);
}
.multiselect__tag {
  background: #d9ebff;
  color: #35495e;
}
.multiselect__tag-icon:focus, .multiselect__tag-icon:hover {
  background: #5aa8fb;

}
</style>