<template>
<div class="row">

    <component v-for="field in fields"
               :is="field.component"
               :field="field"
               :key="field.model"
               :ref="'account'+field.model"
               @invalid="setValidation"
               @push="setPayload"
    />
    <div class="d-grid">

      <b-button class="mt-3"
                type="button"
                variant="primary"
                :disabled="isInvalidForm"
                block
                @click.prevent="push">
        {{ $t('user.activate') }}
      </b-button>
      <b-button class="mt-2"
                type="button"
                variant="secondary"
                @click="closeModal"
                block>
        {{ $t('modal.cancel') }}
      </b-button>
    </div>
</div>
</template>

<script>
import UserText from "@/components/users/UserText.vue";
import UserCheck from "@/components/users/UserCheck.vue";
import UserDate from "@/components/users/UserDate.vue";
import UserPeriod from "@/components/users/UserPeriod.vue";
import {maxValue, minValue, required} from "vuelidate/lib/validators";
import moment from "moment/moment";
import _ from "underscore";
import UserLabel from "@/components/users/UserLabel.vue";
import {mapGetters} from "vuex";


const user = JSON.parse(localStorage.getItem('user'))
export default {
  name: "AccountForm",
  props: ['account', 'modal'],
  data() {
    return {
      fields: [
        {
          label: 'account.balance',
          model: 'balance',
          type: 'number',
          disabled: false,
          component: UserText,
          toPayload: true,
          touch: true,
          validationRules: {
            required,
            minValue: minValue(0),
          },
        },
        {
          label: 'account.unlimited',
          model: 'unlimited',
          type: 'checkbox',
          id: 'unlimited_inside',
          disabled: false,
          component: UserCheck,
          job: this.deactivateBalance,
          toPayload: true,
        },
        {
          label: 'account.limit',
          component: UserLabel
        },
        {
          label: '',
          model: 'limit',
          type: 'number',
          disabled: false,
          component: UserText,
          classWidth: ['half-text','with-post-text'],
          toPayload: true,
          postText: 'account.credits',
          validationRules: {
            required,
            minValue: minValue(1),
            maxValueLimit: maxValue(2147483647)
          }
        },
        {
          label: 'account.for',
          model: 'period',
          type: 'number',
          disabled: false,
          component: UserPeriod,
          toPayload: true,
          postText: 'users_list_params.days',
          classWidth: ['half-text','with-post-text'],
          validationRules: {
            required,
            minValue: minValue(1),
            maxValue: maxValue(7)
          }
        },
        {
          label: 'account.expired_at',
          model: 'expired_at',
          type: 'date',
          disabled: false,
          component: UserDate,
          validationRules: {
            required,
            afterToday: (val) => moment(val, 'YYYY-MM-DD')
                .isSameOrAfter(moment().add(1, 'day'), 'day'),
            toExpired: (val) => {
              return user.account.expired_at ? moment(val, 'YYYY-MM-DD')
                  .isSameOrBefore(moment(user.account.expired_at, 'X'), 'day'): true;
            }
          },
          touch: true

        },
      ],
      payload: {},
      validationArray: [],
      maxBalance: 0
    }
  },
  methods: {
    async setAccountData() {
      this.fields = await this.recountFields();
      this.fields.forEach((item) => {
        const key = 'account'+item.model;
        if(!_.isUndefined(item.model)) {
          try{
            this.$refs[key][0].setValueComponent();
          } catch (e) {
            console.log(key);
          }
        }

      })
    },
    recountFields() {
      return new Promise(resolve => {
        const toReturn = this.fields.map(item => {
          const insertObject = {value: this.account[item.model]};
          if (item.model === 'unlimited' && !user.account.unlimited) {
            insertObject['disabled'] = true;
          }
          return Object.assign(item, insertObject)
        });
        resolve(toReturn);
      })
    },
    setValidation() {
      const validationArray = [];
      for (const field of this.fields) {
        if(!_.isUndefined(field.invalid)) {
          validationArray.push(field.invalid)
        }
      }
      this.validationArray = validationArray;
    },
    deactivateBalance(field) {
      const balanceIndex = this.getFindIndex('balance');
      let payload = {};
      if(field.value) {
        payload = {
          disabled: true,
          value: null,
          placeholder: "∞",
          validationRules: {
            
          }
        }
        this.$nextTick(() => {
          this.$refs['accountbalance'][0].setValueComponent()
        })
      } else {
        payload = {
          disabled: false,
          validationRules: {
            required,
            minValue: minValue(1),
            maxValueBalance: user.account.unlimited ? maxValue(2147483647) : maxValue(this.maxBalance)
          }
        }
      }
      this.fields[balanceIndex] = Object.assign(this.fields[balanceIndex], payload);
    },
    getFindIndex(model) {
      return this.fields.findIndex(item => item.model === model);
    },
    setPayload(field) {
      if (!_.isUndefined(field.job)) {
        field.job(field)
      }
      const insertObject = {};
      insertObject[field.model] = field.value;
      this.payload = Object.assign(this.payload, insertObject);
    },
    closeModal() {
      this.$emit('close');
    },
    push() {
      this.$emit('push', this.payload);
    },
    setMaxBalanceValidation() {
      const balanceFieldIndex = this.getFindIndex('balance');
      this.maxBalance = parseInt(this.account.balance) + parseInt(this.user.account.balance)
      this.fields[balanceFieldIndex].validationRules.maxValueBalance = user.account.unlimited
          ? maxValue(2147483647) : maxValue(this.maxBalance)
    },
    setLimitValidation() {
      const limitField = this.getFindIndex('limit');
      const maxBalance = this.payload.balance;
      if(maxBalance && !this.payload.unlimited) {
        this.fields[limitField].validationRules.maxValue = maxValue(maxBalance)
      } else {
        this.fields[limitField].validationRules.maxValue = maxValue(2147483647);
      }
      const refLink = this.$refs['accountlimit'][0];
      refLink.setValueComponent();
    }
  },
  watch: {
    payload: {
      deep: true,
      handler(val) {
        console.log(val)
      }
    }
    // modal(val, preVal) {
    //   if(preVal === false) {
    //     this.setAccountData();
    //   }
    // }
  },
  mounted() {
    if(this.modal) {
      this.setAccountData();
      this.setMaxBalanceValidation();
    }
  },
  computed: {
    isInvalidForm() {
      return this.validationArray.includes(true);
    },
    ...mapGetters('auth', ['user'])
  }
}
</script>

<style scoped>

</style>