<template>
<div class="form-group" :class="field.classWidth">
  <div class="btn-holder d-flex justify-content-md-end justify-content-start">
    <input type="checkbox" v-model="value" class="btn-check" :id="field.model" :disabled="field.disabled" autocomplete="off">
    <label class="btn"
           :class="{'btn-secondary': value, 'btn-primary': !value}"
           :for="field.model">
      {{ this.value ? $t('user.deactivate') : $t('user.activate') }}
    </label>
  </div>
  <b-modal :id="`activate`"
           v-model="modal"
           centered
           hide-footer
           hide-header
           @hide="closedModal">
    <account-form ref="accountForm"
                  @close="closeModal"
                  @push="setAccount"
                  :modal="modal"
                  :account="field.account"/>

  </b-modal>
</div>
</template>

<script>
import AccountForm from "@/components/users/AccountForm.vue";
export default {
  name: "UserCheckButton",
  components: {AccountForm},
  props: ['field'],
  data() {
    return {
      value: null,
      modal: false,
      isFinishData: false
    }
  },
  watch: {
    value(value, preVal) {
      const field = Object.assign(this.field, {value});
      this.$emit('push', field);
      if(preVal === false && this.field.action === 'edit') {
        this.modal = true;
      }
    },
    '$v.$invalid':  {
      handler(val) {
        this.field = Object.assign(this.field, {invalid: val})
        this.$emit('invalid')
      }
    }
  },
  mount() {
    this.setValueComponent();
  },
  methods: {
    setValueComponent() {
      this.value = this.field.value;
    },
    closedModal() {
      if(!this.isFinishData) {
        this.value = false;
      }
    },
    closeModal() {
      this.modal = false;
    },
    setAccount(data) {
      this.$emit('payload', data);
      this.isFinishData = true;
      this.closeModal();
    }
  }
}
</script>

<style lang="scss">
@media (max-width: 767px) {
  .btn-holder {
    margin-left: 110px;
  }
}
body .btn-check:checked + .btn:hover {
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

body .btn-check + .btn:hover{
  background-color: #73b4ff;
  border-color: #73b4ff;
}


body .btn-check:checked + .btn,
body :not(.btn-check) + .btn:active,
body .btn:first-child:active,
body .btn.active,
body .btn.show {
  background-color: #6c757d;
  border-color: #6c757d;
}

</style>