<template>
  <div class="form-group col-sm-12">
    <div class="row">
      <div class="user-form-label">
        <span>{{ $t('user.Roles') + ' *' }}</span>
      </div>
      <div class="user-form-field border-checkbox-section">
        <select v-model="value" class="form-select" :disabled="field.disabled">
          <option v-for="item in field.items" :key="item.id" :value="item.name" >{{ $t('user.'+item.name) | capitalize }}</option>
        </select>
      </div>
      <div class="col-sm-12" v-show="$v.$anyDirty">
        <user-validation v-for="rule in validationRules"
                         v-if="!$v.value[rule]"
                         :key="`valid${rule}`"
                         :rule="rule"
                         :field="field"/>
      </div>
    </div>
  </div>
</template>

<script>
import filters from "@/mixins/filters";
import _ from "underscore";
import UserValidation from "@/components/users/UserValidation.vue";
import {validationMixin} from "vuelidate";
export default {
  name: "UserSelect",
  props: ['field'],
  mixins: [filters, validationMixin],
  components: {
    UserValidation
  },
  data() {
    return {
      value: null
    }
  },
  validations() {
    return {
      value: !_.isUndefined(this.field.validationRules) ? this.field.validationRules : {}
    }
  },
  computed: {
    validationRules() {
      if(!_.isUndefined(this.field.validationRules)) {
        return Object.keys(this.field.validationRules);
      }
      return []
    }
  },
  watch: {
    value(val) {
      this.pushValue();
    },
    field: {
      deep: true,
      handler(val) {
        this.value = !_.isUndefined(val.value) && val.value.length ? val.value[0] : null;
      }
    },
    '$v.$invalid':  {
      handler(val) {
        this.field = Object.assign(this.field, {invalid: val})
        this.$emit('invalid')
      }
    },
  },
  mounted() {
    this.field = Object.assign(this.field, {invalid: this.$v.$invalid})
    this.$emit('invalid')
    // this.value = !_.isUndefined(this.field.value) && this.field.value.length ? this.field.value[0] : null;
  },
  methods: {
    setValueComponent() {
      this.value = !_.isUndefined(this.field.value) && this.field.value.length ? this.field.value[0] : null;
      if(!_.isNull(this.value) && !this.field.items.map(item => item.name).includes(this.value)) {
        this.field.items.push({name: this.value});
      }
      if(_.isNull(this.value) && !_.isUndefined(this.field.items) && this.field.items.length) {
        this.value = this.field.items.map(item => item.name)[0];
      }
      this.pushValue();
    },
    pushValue() {
      if(!_.isNull(this.value)) {
        this.field.value = [this.value];
      }
      if (this.field.toPayload) {
        this.$emit('push', this.field)
      }
    }
  }
}
</script>

<style scoped>
  .form-select {
    border-radius: 2px;
  }
</style>