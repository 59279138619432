<template>
  <div class="text-danger" >
    {{ $t(`user.${field.model}_${rule}`) }}
    {{ rule === 'maxValueBalance' ? this.raw.$params.maxValueBalance.max : '' }}
    {{ rule === 'maxValueLimit' ? this.raw.$params.maxValueLimit.max : '' }}
    {{ rule === 'toExpired' ? moment(user.account.expired_at, 'X').format('DD.MM.YYYY'): ''}}
  </div>

</template>

<script>
import moment from "moment";
export default {
  name: "UserValidation",
  props: ['field', 'rule', 'raw'],
  setup() {
    return {
      moment,
      user: JSON.parse(localStorage.getItem('user'))
    }
  },
}
</script>

<style scoped>

</style>