<template>
<div class="user-form-label">
  {{$t(field.label)}} *
</div>
</template>

<script>
export default {
  name: "UserLabel",
  props: ['field']
}
</script>

<style scoped>

</style>