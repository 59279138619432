<template>
  <div class="form-group user-text" :class="field.classWidth">
    <div class="row">
      <div class="user-form-label" v-if="field.label">
        {{ $t(field.label) }}
        <span
            v-if="validationRules.includes('required') && !field.hasOwnProperty('postText')">
          *
        </span>
      </div>
      <div class="user-form-field">
        <b-form-input v-if="field.type === 'text'"
                      :type="field.type"
                      :disabled="field.disabled"
                      v-model="value"
                      :max="field.model === 'user.login' ? 20 : 255"
                      @input="$v.value.$touch" />
        <input v-else-if="field.type === 'number'"
                      autocomplete="off"
                      class="form-control"
                      min="0"
                      pattern="[0-9]+"
                      type="text"
                      :placeholder="this.field.placeholder || ''"
                      :disabled="field.disabled"
                      v-model="value"
                      @input="$v.value.$touch" />
      </div>
      <div class="user-form-post-text" v-if="field.hasOwnProperty('postText')">
        {{ $t(field.postText) }}
      </div>
      <div class="col-sm-12" v-show="$v.$anyDirty">
        <user-validation v-for="(rule, key) in Object.keys($v.value.$params)"
                         :key="`validation${key}`"
                         v-if="!$v.value[rule]"
                         :rule="rule"
                         :raw="$v.value"
                         :field="field"/>
      </div>
    </div>

  </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import _ from "underscore";
import UserValidation from "@/components/users/UserValidation.vue";

export default {
  name: "UserText",
  components: {UserValidation},
  props: ['field'],
  mixins: [validationMixin],
  data() {
    return {
      value: null,
      validationRules: []
    }
  },
  validations() {
    return {
      value: !_.isUndefined(this.field.validationRules) ? this.field.validationRules : {}
    }
  },
  computed: {
    // validationRules() {
    //   if(!_.isUndefined(this.field.validationRules)) {
    //     return Object.keys(this.field.validationRules);
    //   }
    //   return []
    // }
  },
  watch: {
    value(value) {
      if(this.field.type === 'number') {
        this.value = parseInt(
            value.toString().replace(/[^0-9]/i, '')
        ) || 0;
      }
      if(value !== null) {
        this.$v.value.$touch();
      }
      const field = Object.assign(this.field, {value});
      this.$emit('push', field);
    },
    '$v.$invalid':  {
      handler(val) {
        this.field = Object.assign(this.field, {invalid: val})
        this.$emit('invalid')
      }
    },
    field: {
      deep: true,
      handler() {
        this.setValidationRules();
      }
    }
  },
  methods: {
    setValueComponent() {
      this.value = this.field.value;
      this.pushValidation();
      if (this.field.toPayload) {
        this.$emit('push', this.field)
      }
      if(this.value !== null) {
        this.$v.value.$touch();
      }
    },
    pushValidation() {
      this.field = Object.assign(this.field, {invalid: this.$v.$invalid})
      this.$emit('invalid')
    },
    setValidationRules() {
      if(!_.isUndefined(this.field.validationRules)) {
        this.validationRules = Object.keys(this.field.validationRules);
      } else {
        this.validationRules = []
      }
    }
  },
  mounted() {
    this.setValidationRules();
  }
}
</script>
<style>

</style>

