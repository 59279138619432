<template>
  <div class="form-group col-sm-12">
    <div class="row">
      <div class="user-form-label">
        <label for="">{{ $t(field.label) }}</label>
      </div>
      <div class="user-form-field">
        <b-form-textarea :disabled="field.disabled" v-model="value" ></b-form-textarea>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserTextarea",
  props: ['field'],
  data() {
    return {
      value: null
    }
  },
  watch: {
    value(value) {
      const field = Object.assign(this.field, {value});
      if(value) {
        this.$emit('push', field);
      }
    }
  },
  mounted() {
    this.value = this.field.value;
  },
  methods: {
    setValueComponent() {
      this.value = this.field.value;
    }
  }
}
</script>

<style scoped>

</style>