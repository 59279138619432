<template>
  <div>
    <form @submit.prevent="submitDecorateModal()" autocomplete="off">
      <div class="row">
        <component :is="field.component" v-for="field in fields" :key="field.label" :field="field"
          :ref="`field_${field.model}`" @invalid="setValidation" @push="setPayload" @payload="updateAccount" />
      </div>
      <div v-if="error.length">
        <p v-if="error === 'Access denied.'" class="text-danger">{{ $t('errors.AccessDenied') }}</p>
        <p v-else class="text-danger">{{ $t(`errors.${error}`) }}</p>
      </div>
      <div class="row justify-content-between flex-column flex-md-row-reverse">
        <div class="col-lg-3 col-md-4 col-sm-6 col-9 form-group d-flex justify-content-sm-end verification-btn-wrapper">
          <b-button variant="primary" type="button" class="lower-button" :disabled="verificationLoading"
            @click="$refs.resetModal.show()" v-if="action === 'edit' && initialData.parent_id === activeUser.id">{{
              $t('addVerification.reset_verification') }} <b-spinner small
              v-if="verificationLoading"></b-spinner></b-button>
        </div>
        <div class="col-9 col-sm-6 d-flex d-md-block justify-content-between justify-content-md-start action-form-btns">
          <b-button type="submit" variant="primary" :disabled="isInvalidForm" class="m-r-10">
            {{ action === 'create' ? $t('user.create') : $t('user.save') }}
            <b-spinner small v-if="submitLoading"></b-spinner>
          </b-button>
          <router-link :to="{ name: 'Users' }" class="btn btn-secondary">{{ $t('modal.cancel') }}</router-link>
        </div>
      </div>

    </form>

    <b-modal :id="`activate`" v-model="promptModal" centered hide-footer hide-header>
      <p v-for="reason in promptReason">{{ reason }}</p>
      <div class="d-grid">

        <b-button class="mt-3" type="button" variant="primary" block @click.prevent="submit()">
          {{ $t('modal.yes') }}
        </b-button>
        <b-button class="mt-2" type="button" variant="secondary" @click="closeModal" block>
          {{ $t('modal.no') }}
        </b-button>
      </div>
    </b-modal>
    <b-modal ref="resetModal" centered hide-footer hide-header>
      <h4 class="text-center" style="font-size: 18px;">{{ $t('addVerification.will_be_reset') }}</h4>
      <div class="d-grid">

        <b-button class="mt-3" type="button" variant="primary" block @click.prevent="resetVerification">
          {{ $t('modal.yes') }}
        </b-button>
        <b-button class="mt-2" type="button" variant="secondary" @click="$refs.resetModal.hide()" block>
          {{ $t('modal.no') }}
        </b-button>
      </div>
    </b-modal>
    <b-modal ref="resetModalOk" centered hide-footer hide-header>
      <h4 class="d-flex align-items-center" style="font-size: 18px;"><i style="font-size: 28px;" class="feather icon-alert-circle text-warning m-r-10"></i><span>{{ $t('addVerification.warning_reset') }}</span></h4>
      <div class="d-grid">

        <b-button class="mt-3" type="button" variant="primary" block @click.prevent="$refs.resetModalOk.hide()">
          {{ $t('ui.Ok') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>

import UserText from "@/components/users/UserText.vue";
import UserTextarea from "@/components/users/UserTextarea.vue";
import UserSelect from "@/components/users/UserSelect.vue";
import UserDate from "@/components/users/UserDate.vue";
import UserCheck from "@/components/users/UserCheck.vue";
import UserCheckers from "@/components/users/UserCheckers.vue";
import UserCheckButton from "@/components/users/UserCheckButton.vue";
import UserLabel from "@/components/users/UserLabel.vue";
import UserPeriod from "@/components/users/UserPeriod.vue";
import _ from "underscore";
import moment from "moment";
import axios from "@/configs/axios"
import {
  required, minLength, maxLength, maxValue, minValue,
  helpers
} from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
const user = JSON.parse(localStorage.getItem('user'))

export default {
  name: "UserForm",
  props: ['initialData', 'action'],
  setup() {
    return {
      activeUser: user
    }
  },
  data() {
    return {
      fields: [
        {
          label: 'user.first_name',
          model: 'first_name',
          type: 'text',
          disabled: false,
          component: UserText,
          classWidth: ['col-sm-12'],
          validationRules: {
            required,
            maxLength: maxLength(255)
          }
        },
        {
          label: 'user.last_name',
          model: 'last_name',
          type: 'text',
          disabled: false,
          component: UserText,
          classWidth: ['col-sm-12'],
          validationRules: {
            required,
            maxLength: maxLength(255)
          }
        },
        {
          label: 'user.login',
          model: 'login',
          type: 'text',
          disabled: this.action === 'edit',
          component: UserText,
          classWidth: ['col-sm-12'],
          validationRules: {
            required,
            maxLength: maxLength(20),
            onlyCharacters: (val) => /^[a-zA-z0-9]*$/.test(val)
          }
        },
        {
          label: 'user.password',
          model: 'password',
          type: 'text',
          disabled: false,
          component: UserText,
          classWidth: ['col-sm-12'],
          validationRules: {
            required,
            minLength: minLength(4)
          }
        },
        {
          label: 'user.roles',
          model: 'roles',
          type: 'select',
          disabled: true,
          component: UserSelect,
          classWidth: ['col-sm-12'],
          toPayload: true,
          validationRules: {
            required
          }
        },
        {
          label: 'user.note',
          model: 'note',
          type: 'textarea',
          disabled: false,
          component: UserTextarea,
          classWidth: ['col-sm-12'],
        },
        {
          label: 'account.balance',
          model: 'account.balance',
          type: 'number',
          disabled: false,
          component: UserText,
          maxValueLabel: 'user.maxValueBalance',
          classWidth: ['col-sm-12'],
          validationRules: {
            required,
            minValue: minValue(1),
            // maxValueBalance: user.account.unlimited ? maxValue(2147483647) : maxValue(user.account.balance)
          },
        },
        {
          label: 'account.unlimited',
          model: 'account.unlimited',
          type: 'checkbox',
          id: 'unlimited',
          toPayload: true,
          disabled: false,
          component: UserCheck,
          classWidth: ['col-sm-12'],
          job: this.deactivateBalance
        },
        {
          label: 'account.limit',
          component: UserLabel
        },
        {
          postText: 'account.for',
          model: 'account.limit',
          type: 'number',
          disabled: false,
          component: UserText,
          classWidth: ['half-text', 'text-credits'],
          label: 'account.credits',
          validationRules: {
            required,
            maxValueLimit: maxValue(2147483647),
            minValue: this.action === 'create' ? minValue(1) : minValue(0)
          }
        },
        {
          label: '',
          model: 'account.period',
          type: 'number',
          disabled: false,
          component: UserPeriod,
          postText: 'users_list_params.days',
          classWidth: ['half-text', 'text-days'],
          touch: true,
          validationRules: {
            required,
            minValue: minValue(1),
            maxValue: maxValue(7)
          }
        },
        {
          label: 'account.expired_at',
          model: 'account.expired_at',
          type: 'date',
          disabled: false,
          component: UserDate,
          classWidth: ['col-lg-9', 'col-md-8'],
          touch: this.action === 'edit',
          validationRules: {
            required,
            afterToday: (val) => moment(val, 'YYYY-MM-DD')
              .isSameOrAfter(moment().add(1, 'day'), 'day'),
            toExpired: (val) => {
              return user.account.expired_at ? moment(val, 'YYYY-MM-DD')
                .isSameOrBefore(moment(user.account.expired_at, 'X'), 'day') : true;
            }

          }
        },
        {
          label: 'account.is_active',
          model: 'account.is_active',
          type: 'checkbox',
          id: 'is_active',
          disabled: false,
          component: UserCheckButton,
          toPayload: true,
          job: this.deactivateExpired,
          action: this.action,
          classWidth: ['col-lg-3', 'col-md-4'],
        },
        {
          label: 'user.permissions',
          model: 'permissions',
          component: UserCheckers,
          usedValue: 'name',
          items: [],
          disabled: true,
          classWidth: ['col-sm-12'],
          translation: true
        },
      ],
      validationArray: [],
      payload: {},
      account: {},
      promptModal: false,
      childrenCount: 0,
      error: "",
      submitLoading: false,
      promptReason: [],
      maxBalance: 0,
      verificationLoading: false
    }
  },
  methods: {
    setInitialData(data) {
      return new Promise(resolve => {
        this.fields = this.fields.map(item => {
          const valueObj = !_.isUndefined(item.model) ? item.model.split('.') : '';
          const value = (() => {
            if (valueObj.length === 1) {
              return data[valueObj[0]]
            } else if (valueObj.length === 2) {
              return data[valueObj[0]][valueObj[1]]
            }
          })()
          if (_.isUndefined(value)) {
            return item
          }

          const insertObject = { value };
          if (item.model === 'account.is_active') {
            insertObject['account'] = this.account;
          }
          if (item.model === 'account.unlimited' && !user.account.unlimited) {
            insertObject['disabled'] = true;
          }
          // eslint-disable-next-line no-prototype-builtins
          if (data.hasOwnProperty('id') && user.id === data.id && item.model !== 'password') {
            insertObject['disabled'] = true;
          }
          return Object.assign(item, insertObject)
        })
        resolve(this.fields);
      })
    },
    setValuesToComponents() {
      this.fields.forEach(item => {
        if (!_.isUndefined(item.model)) {
          const itemKey = `field_${item.model}`;
          try {
            this.$refs[itemKey][0].setValueComponent();
          } catch (e) {
            console.log(e)
            console.error(itemKey);
          }
        }
      })
    },
    setValidation() {
      const validationArray = [];
      for (const field of this.fields) {
        if (!_.isUndefined(field.invalid)) {
          validationArray.push(field.invalid)
        }
      }
      this.validationArray = validationArray;
    },
    setPayload(field) {
      if (!_.isUndefined(field.job)) {
        field.job(field)
      }
      const key = field.model.split('.');
      if (!_.isUndefined(field.value)) {
        if (key.length === 1) {
          this.payload[key[0]] = field.value
        } else if (key.length === 2) {
          try {
            if (_.isUndefined(this.payload[key[0]])) {
              this.payload[key[0]] = {};
            }
            this.payload[key[0]][key[1]] = field.value;
          } catch (e) {
            console.log(e)
          }
        }
      }
      this.promptReason = this.setPromptReason();
    },
    submitDecorateModal() {
      if (!this.isPromptModal) {
        this.submit();
      } else {
        this.promptModal = true;
      }
    },
    submit() {
      switch (this.action) {
        case 'edit':
          this.submitEdit();
          break;
        case 'create':
          this.submitCreate();
          break;
      }
    },
    submitEdit() {
      this.payload['id'] = this.$route.params.id;
      this.submitLoading = true;
      this.$store.dispatch('users/updateUser', this.payload).then(() => {
        this.$router.push({ name: 'Users' })
      }).catch(e => {
        const message = e.response.data.message;
        if (message === 'The login has already been taken.') {
          this.error = 'login_taken';
        } else if (message === 'The account.is active must be declined.') {
          this.error = 'activate_with_null_balance';
        } else {
          this.error = message;
        }
      }).finally(() => {
        this.submitLoading = false;
      });
    },
    submitCreate() {
      this.submitLoading = true;
      this.$store.dispatch('users/storeUser', this.payload).then(() => {
        this.$router.push({ name: 'Users' })
      }).catch(e => {
        const message = e.response.data.message;
        if (message === 'The login has already been taken.') {
          this.error = 'login_taken';
        } else if (message === 'The account.is active must be declined.') {
          this.error = 'activate_with_null_balance';
        } else {
          this.error = message;
        }
      }).finally(() => {
        this.submitLoading = false;
      });
    },
    setItemsToField(data, model) {
      this.fields.forEach((element, index) => {
        if (element.model === model) {
          this.fields[index] = Object.assign(element, { items: data.data, disabled: !data.data.length || this.initialData.id === user.id });
        }
      })
    },
    setItemValuesToField(initialParam, payloadParam) {
      const ids = this.initialData[initialParam].map(item => item.id);
      this.fields.forEach((element, index) => {
        if (element.model === payloadParam) {
          this.fields[index] = Object.assign(element, { value: ids })
        }
      })
    },
    getFindIndex(model) {
      return this.fields.findIndex(item => item.model === model);
    },
    deactivateBalance(field) {
      const balanceIndex = this.getFindIndex('account.balance');
      let payload = {};
      if (field.value) {
        payload = {
          disabled: true,
          value: null,
          placeholder: "∞",
          validationRules: {
            minValue: minValue(0),
            maxValueBalance: this.user.account.unlimited ? maxValue(2147483647) : maxValue(this.maxBalance)
          }
        }
        this.$nextTick(() => {
          this.$refs['field_account.balance'][0].setValueComponent()
        })
      } else {
        payload = {
          disabled: this.initialData.id === user.id,
          placeholder: undefined,
          validationRules: {
            required,
            minValue: this.action === 'create' ? minValue(1) : minValue(0),
            maxValueBalance: this.user.account.unlimited ? maxValue(2147483647) : maxValue(this.maxBalance)
          }
        }
      }
      this.fields[balanceIndex] = Object.assign(this.fields[balanceIndex], payload);
    },
    deactivateExpired(field) {
      const expiredIndex = this.getFindIndex('account.expired_at');
      const payload = {}
      payload['disabled'] = !field.value;
      // eslint-disable-next-line no-prototype-builtins
      if (this.initialData.hasOwnProperty('id') && this.initialData.id === user.id) {
        payload['disabled'] = true;
      }
      this.fields[expiredIndex] = Object.assign(this.fields[expiredIndex], payload);
    },
    loadItems() {
      const userId = this.action === 'edit' ? this.initialData.parent_id : user.id;
      const payload = (() => {
        if (this.action === 'edit' && userId !== user.id && this.initialData.id !== user.id) {
          return {
            parentId: userId
          };
        }
        return {}
      })()

      this.$store.dispatch('users/getPermissions', payload).then(data => {
        this.setItemsToField(data, 'permissions')
      });
      // if(this.action === 'create') {
      //   this.$store.dispatch('source_groups/getGroupList', payload).then( data => {
      //     this.setItemsToField(data, 'sources_groups_ids');
      //     this.setItemValuesToField('sources_groups', 'sources_groups_ids');
      //   })
      //   this.$store.dispatch('service_groups/getGroupList', payload).then((data) => {
      //     this.setItemsToField(data, 'services_groups_ids');
      //     this.setItemValuesToField('services_groups', 'services_groups_ids');
      //   })
      // }
    },
    async mountAction() {
      this.account = this.initialData.account;
      await this.setInitialData(this.initialData);
      await this.setValuesToComponents();
      await this.setMaxBalanceValidation();
      await this.loadItems();
    },
    setPasswordValidation() {
      const passwordFieldIndex = this.getFindIndex('password');
      const validationObject = { minLength: minLength(6) };
      if (this.action !== 'create') {
        this.fields[passwordFieldIndex].validationRules = validationObject;
      }
    },
    updateAccount(data) {
      this.account = Object.assign(this.account, data);
      this.payload.account = Object.assign(this.payload.account, data);
      this.setInitialData(this.payload);
      this.setValuesToComponents()
    },
    setRoles() {
      const userId = this.action === 'edit' ? this.initialData.parent_id : user.id;
      const payload = (() => {
        if (this.action === 'edit' && userId !== user.id && this.initialData.id !== user.id) {
          return {
            parentId: userId
          };
        }
        return {}
      })()
      this.$store.dispatch('users/getRoles', payload).then(() => {
        const rolesFieldIndex = this.getFindIndex('roles')
        if (this.action === 'create') {
          this.fields[rolesFieldIndex].disabled = false
        }
        this.fields[rolesFieldIndex].items = this.roles;
        // this.$refs.field_roles[0].setValueComponent();
      })

      // this.$store.dispatch('users/getRoles', payload).then(async () => {
      //       const rolesFieldIndex = this.getFindIndex('roles')
      //
      //       this.fields[rolesFieldIndex].items = await this.sortRoles();
      //       await this.$refs.field_roles[0].setValueComponent();
      //       if(this.action === 'create') {
      //         this.fields[rolesFieldIndex].disabled = false
      //       }

    },

    // async sortRoles(){
    //   const temp = JSON.parse(JSON.stringify(this.roles));
    //   if (this.user.roles.includes('admin') || this.user.roles.includes('frontliner') || this.user.roles.includes('superadmin')){
    //     let index =  this.roles.findIndex(item => item.name === "operator");
    //     temp.splice(0, 0, temp.splice(index, 1)[0]);
    //   }
    //   return temp
    // },

    closeModal() {
      this.promptModal = false;
    },
    setChildrenCount(count) {
      this.childrenCount = count;
    },
    setPromptReason() {
      const reasons = [];
      const isSales = user.roles.includes('developer') || user.roles.includes('superadmin') || user.roles.includes('frontliner');
      const isAction = !!Object.keys(this.payload).length && !_.isUndefined(this.payload.account)
      if (isAction && (this.payload.account.balance === 0 && !this.payload.account.unlimited && !this.childrenCount) || isAction && (this.payload.account.balance === 0 && !this.payload.account.unlimited && isSales)) {
        reasons.push(this.$t('user.reason.reasonZero'));
      }
      if (isAction && (!this.payload.account.is_active) && !!this.childrenCount) {
        if (isSales) {
          reasons.push(this.$t('user.reason.notActiveSales'));
        } else {
          reasons.push(this.$tc('user.reason.notActive', this.childrenCount));
        }
      }
      if (isAction && (!this.payload.account.unlimited && this.initialData.account.unlimited) && !!this.childrenCount) {
        if (isSales) {
          reasons.push(this.$t('user.reason.balanceChangeSales'));
        } else {
          reasons.push(this.$tc('user.reason.balanceChange', this.childrenCount));
        }
      }
      return reasons;
    },
    setMaxBalanceValidation() {
      const balanceFieldIndex = this.getFindIndex('account.balance');
      const accountBalance = this.initialData.account.balance ? parseInt(this.initialData.account.balance) : 0;
      this.maxBalance = accountBalance + parseInt(this.user.account.balance)
      this.fields[balanceFieldIndex].validationRules.maxValueBalance = user.account.unlimited ? maxValue(2147483647) : maxValue(this.maxBalance)
    },
    setLimitValidation() {
      const limitField = this.getFindIndex('account.limit');
      const maxBalance = this.payload.account.balance;
      if (maxBalance && !this.payload.account.unlimited) {
        this.fields[limitField].validationRules.maxValue = maxValue(maxBalance)
      } else {
        this.fields[limitField].validationRules.maxValue = maxValue(2147483647);
      }
      const refLink = this.$refs['field_account.limit'][0];
      refLink.setValueComponent();
    },
    resetVerification() {
      this.$refs.resetModal.hide();
      const userId = Object.keys(this.$route.params).length ? this.$route.params.id : null;
      this.verificationLoading = true;
      axios({ method: 'GET', url: `admin/user/${userId}/reset_verification` })
        .then(() => {
          this.verificationLoading = false;
          // this.$toast.warning(this.$t('addVerification.warning_reset'),
          //   {
          //     position: "top-center",
          //     timeout: 100000,
          //     closeOnClick: true,
          //     pauseOnFocusLoss: true,
          //     pauseOnHover: true,
          //     draggable: true,
          //     draggablePercent: 0.6,
          //     showCloseButtonOnHover: false,
          //     hideProgressBar: true,
          //     closeButton: "button",
          //     icon: true,
          //     rtl: false
          //   });
          this.$refs.resetModalOk.show();
        })
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('users', ["roles"]),
    isInvalidForm() {
      return this.validationArray.includes(true);
    },
    isPromptModal() {
      return !!this.promptReason.length
    },
  },
  watch: {
    'payload': {
      deep: true,
      handler(val) {
        console.log(val)
        this.account = Object.assign(this.account, val)
      }
    }
  },
  async mounted() {
    this.setPasswordValidation();
    if (Object.keys(this.initialData).length) {
      await this.mountAction();
    }
    await this.setRoles();
  }
}
</script>

<style>
.row>.user-form-label {
  flex: 0 2 auto;
  width: 110px;
  line-height: 30px;
}

.user-form-field {
  flex: 0 1 calc(100% - 110px);
  width: calc(100% - 110px);
}

body .multiselect__tags {
  max-height: max-content;
}

.with-post-text .user-form-label:not(:empty) {
  width: 55px;
  line-height: normal;
  margin-left: -10px;
}

@media (max-width:767px) {
  .with-post-text .user-form-label:not(:empty) {
    margin-left: 0;
  }
}

.with-post-text .row>.user-form-label:empty {
  width: 0;
}

.with-post-text .row>.user-form-field {
  flex: 0 1 calc(100% - 100px);
}

.with-post-text .row>.user-form-post-text {
  flex: 0 1 30px;
  padding-left: 0;
  line-height: 30px;
  text-transform: lowercase;
}

.text-credits .row>.user-form-label {
  flex: 0 1 auto;
  width: 70px;
}

.text-credits .row>.user-form-field {
  flex: 0 1 calc(100% - 155px)
}

.text-credits .row>.user-form-post-text {
  flex: 0 2 auto;
  width: fit-content;
  padding-left: 0;
  line-height: 30px;
}

.text-days .row>.user-form-field {
  flex: 0 1 calc(100% - 70px)
}

.text-days .row>* {
  width: auto;
  /* max-width: auto; */
}

.text-credits .row>* {
  width: auto;
  /* max-width: 100%; */
}

@media (max-width: 767px) {
  .text-days {
    margin-left: 110px;
  }

  .text-days .row>.user-form-field {
    flex: 0 1 calc(100% - 190px);
  }

  .text-credits .row>.user-form-field {
    flex: 0 1 calc(100% - 190px);
    margin-left: 40px;
  }
}

.text-days .row>.user-form-post-text {
  flex: 0 2 auto;
  width: fit-content;
  padding-right: 0;
  line-height: 30px;
  text-transform: lowercase;
}

.multiselect__tags {
  max-height: 40px;
}

@media (min-width: 768px) {

  .half-text,
  .row>.half-text {
    width: calc(50% - 55px);
    padding-right: 0;
  }
}

@media (max-width: 767px) {

  .half-text,
  .row>.half-text {
    width: 100%;
  }

  .action-form-btns .btn {
    width: calc(50% - 5px);
  }
}

@media (min-width: 768px) {

  .btn-holder label.btn,
  .verification-btn-wrapper .btn {
    width: 100%;
  }
}

.verification-btn-wrapper .btn {
  width: 100%;
}
</style>