<template>
  <div class="form-group" :class="field.classWidth">
    <div class="row">
      <div class="user-form-label">
        {{ $t(field.label) + ' *' }}
      </div>
      <div class="user-form-field">
        <date-picker v-model="value"
                     type="date"
                     valueType="YYYY-MM-DD"
                     format="DD.MM.YYYY"
                     :editable="false"
                     @input="$v.value.$touch"
                     :lang="lang[$i18n.locale]"
                     :disabled-date="disabledRange"
                     :disabled="field.disabled"></date-picker>
      </div>
      <div class="col-sm-12" v-show="$v.$anyDirty">
        <user-validation v-for="rule in validationRules"
                         v-if="!$v.value[rule]"
                         :key="`valid${rule}`"
                         :rule="rule"
                         :field="field"/>
      </div>
    </div>
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import _ from "underscore";
import UserValidation from "@/components/users/UserValidation.vue";
import moment from "moment/moment";
import {mapGetters} from "vuex";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
export default {
  setup() {
    return {
      moment,
      user: JSON.parse(localStorage.getItem('user')),
      lang: {
        ua: {
          formatLocale: {
            months: ['січень', 'лютий', 'березень', 'квітень', 'травень', 'червень', 'липень', 'серпень', 'вересень', 'жовтень', 'листопад', 'грудень'],
            monthsShort: ['січ', 'лют', 'бер', 'квіт', 'трав', 'черв', 'лип', 'серп', 'вер', 'жовт', 'лист', 'груд'],
            weekdays: ['неділя', 'понеділок', 'вівторок', 'середа', 'четвер', 'п’ятниця', 'субота'],
            weekdaysShort: ['нд', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
            weekdaysMin: ['нд', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
            firstDayOfWeek: 1,
            firstWeekContainsDate: 7
          }
        },
        ru: {
          formatLocale: {
            months: ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'],
            monthsShort: ['янв.', 'февр.', 'март', 'апр.', 'май', 'июнь', 'июль', 'авг.', 'сент.', 'окт.', 'нояб.', 'дек.'],
            weekdays: ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'],
            weekdaysShort: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
            weekdaysMin: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
            firstDayOfWeek: 1,
            firstWeekContainsDate: 1
          }
        },
        en: {
          formatLocale: {
            months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            weekdaysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
            firstDayOfWeek: 0,
            firstWeekContainsDate: 1
          }
        }
      }
    }
  },
  name: "UserDate",
  components: {UserValidation, DatePicker},
  props: ['field'],
  mixins: [validationMixin],
  data() {
    return {
      value: null
    }
  },
  validations() {
    return {
      value: !_.isUndefined(this.field.validationRules) ? this.field.validationRules : {}
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    validationRules() {
      if(!_.isUndefined(this.field.validationRules)) {
        return Object.keys(this.field.validationRules);
      }
      return []
    }
  },
  watch: {
    // field: {
    //   deep: true,
    //   handler(val) {
    //     if(typeof val.value === 'number') {
    //       this.value = moment(val.value, 'X').format('YYYY-MM-DD');
    //     }
    //   }
    // },
    value(value) {
      const field = Object.assign(this.field, {value});
      this.$emit('push', field);
    },
    '$v.$invalid':  {
      handler(val) {
        this.field = Object.assign(this.field, {invalid: val})
        this.$emit('invalid')
      }
    },
  },
  methods: {
    setValueComponent() {
      if(Number.isInteger(this.field.value)) {
        this.value = moment(this.field.value, 'X').format('YYYY-MM-DD');
      } else {
        this.value = this.field.value;
      }
      if(this.field.touch) {
        this.$v.value.$touch();
      }
      this.pushValidation();
    },
    pushValidation() {
      this.field = Object.assign(this.field, {invalid: this.$v.$invalid})
      this.$emit('invalid')
    },
    disabledRange(date) {
      return date < moment().toDate()
          || moment(this.user.account.expired_at, 'X').toDate() <= date;
    }
  },
  mount() {
    // this.value = moment(this.field.value, 'X').format('YYYY-MM-DD');
    // this.field = Object.assign(this.field, {invalid: this.$v.$invalid})
  },

}
</script>
<style>
.mx-datepicker {
  width: 100%;
}
.mx-input {
  border-radius: 2px;
}
</style>

